document.addEventListener("DOMContentLoaded", function(){
  if(carouselLoad2 == false) {
    carouselLoad2 = true;
    Array.from(document.querySelectorAll('#recipe-slider')).forEach(function(el,i){
      let carousel = tns({
        container: el,
        items: 1,
        speed: 500,
        gutter: 10,
        controls: true,
        controlsContainer: '#recipe-controls .slider-controls__btns',
        nav: true,
        navPosition: 'bottom',
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        swipeAngle: 25,
        touch: true,
        loop: false,
        rewind: true,
        fixedWidth: 240,
        responsive: {
          576: {
            items: 2,
            fixedWidth: false
          },
          768: {
            items: 3
          },
          992: {
            nav: false,
            slideBy: 'page'
          },
          1200:{
            gutter: 30
          }
        },
        onInit: function(slider){
          if (slider.slideCount < 2){
            document.querySelector('#recipe-controls').remove();
          }else{
            sliderCountChage('#recipe-controls', 1, slider.pages);
          }
          
        }
        
      }).events.on('indexChanged',function(slider,eventName){
        let indexPrev = slider.indexCached,
            indexCurrent = slider.index;
        let page = Number(document.querySelector('#recipe-controls .slider-controls__count span')
          .textContent);
        if (indexCurrent > indexPrev){
          page++;
        }else{
          page--;
        }
        if (indexCurrent === 0){
          page = 1;
        }
        if (indexCurrent > slider.pages){
          page = slider.pages;
        }
        sliderCountChage('#recipe-controls', page, slider.pages);
      });
      function sliderCountChage(id,index,pages){
        document.querySelector(id + ' .slider-controls__count span')
          .textContent = index;
        document.querySelector(id + ' .slider-controls__count small')
          .textContent = '/' + pages;
      }
    });
  }
});
