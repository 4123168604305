$(document).on('click','.js-like',function(e){
  e.preventDefault();
  let like = $(this);
  let count = Number(like.text());
  if (like.hasClass('is-selected')){
    count--;
  }else{
    count++;
  }
  like.text(count);
  like.toggleClass('is-selected');
})
