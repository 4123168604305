document.addEventListener("DOMContentLoaded", function(){

  Array.from(document.querySelectorAll('.js-slider-news')).forEach(function(el,i){
    let carousel = tns({
      container: el,
      items: 1,
      speed: 500,
      gutter: 10,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      mouseDrag: true,
      preventScrollOnTouch: 'auto',
      swipeAngle: 25,
      touch: true,
      loop: false,
      rewind: true,
      responsive: {
        576: {
          items: 2,
          gutter: 30
        }
      },
      onInit: function(slider){
       
      }

    });
  });

  Array.from(document.querySelectorAll('.js-slider-news4')).forEach(function(el,i){
    let carousel = tns({
      container: el,
      items: 1,
      speed: 500,
      gutter: 10,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      mouseDrag: true,
      preventScrollOnTouch: 'auto',
      swipeAngle: 25,
      touch: true,
      loop: false,
      rewind: true,
      responsive: {
        576: {
          items: 2
        },
        768: {
          items: 3
        },
        992: {
          items: 4,
          gutter: 30
        },
      },
      onInit: function(slider){

      }

    });
  });
});
