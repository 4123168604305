$(document).ready(function () {

  var productCardLink = $('.product-card__link')
  productCardLink.on('click', function (e) {
    e.preventDefault()
    var target = $(this).attr('href')
    $('.product-card__link').removeClass('is-active')
    $(this).addClass('is-active');
    $('.product-card__img').removeClass('product-card__img--active')
    $(target).addClass('product-card__img--active')
    $('.product-card__prop-wrapper').toggleClass('product-card__prop-wrapper--active')
  })

})