/*let scale = 1;
let currentScroll = 0;
$(window).scroll(function(e){
  let h = $(window).height();
  let scroll = $(document).scrollTop();
  let down = true;
  if (scroll < currentScroll){
    down = false;
  }
  currentScroll = scroll;
  if(down){
    scale-= 0.15;
    if (scale<0){
      scale = 0
    }
  }else{
    scale+= 0.15;
    if (scale>1){
      scale = 1;
    }
  }
  $('#wave').css('transform','scaleY('+scale+')');
});*/

jQuery(document).ready(function ($) {
  
  if ($('.section__wave-scroll').length) {
    
    var controller = new ScrollMagic.Controller()
    var tween = TweenMax.to(".section__wave-scroll", 0.5, {scaleY:0});

    var scene = new ScrollMagic.Scene({
      triggerHook: '0',
      triggerElement: ".js-wave-trigger", 
      duration: '100%'
    })
      .setTween(tween)
      .addTo(controller);
    
  }
  
})
