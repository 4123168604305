$(document).ready(function () {
  bsCustomFileInput.init();
})
function customFile(input){
  let inp = $(input);
  let wrap = inp.parents('.custom-file');
  
  if(inp.val()){
    wrap.addClass('is-selected');
    wrap.find('.custom-file-label').text(inp[0].files[0].name).append('<span class="custom-file__clear" onclick="clearFileInput(event,this)"></span>');
  }else{
    wrap.removeClass('is-selected');
    wrap.find('.custom-file__clear').remove();
  }
}
function clearFileInput(e,btn){
  e.preventDefault();
  let wrap = $(btn).parents('.custom-file');
  let input = wrap.find('input');
  input.val('').trigger('change');
  wrap.find('.custom-file-label').text(input.data('label'));
}

jQuery(document).ready(function ($) {

  var input = $('.section-form__input')
  var label = $('.section-form__label')

  input.on('keydown', function () {
    $(this).addClass('is-active')
    $(this).siblings(label).addClass('is-active')
  })
  input.on('blur', function () {
    if ( $(this).val() === '' ) {
      $(this).removeClass('is-active')
      $(this).siblings(label).removeClass('is-active')
    } else {
      $(this).addClass('is-active')
      $(this).siblings(label).addClass('is-active')
    }
  })

})

$(window).on('load', function() {
  $('.section-form__input').each(function () {
    if ( $(this).val() !== '' ) {
      $(this).addClass('is-active')
      $(this).siblings('.section-form__label').addClass('is-active')
    }
  })
})

window.addEventListener('load', function() {

  var forms = document.getElementsByClassName('needs-validation')

  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(e) {
      if (form.checkValidity() === false) {
        form.classList.add('was-validated')
      }
      e.preventDefault()
      e.stopPropagation()
    }, false)
  })
  
})

