$(document).ready(function(e){
  $('.js-video-hover').hover(function(e){
    $(this).find('video')[0].play();
  },function(){
    $(this).find('video')[0].pause();
  });

  /*$('.js-video-hover').on('click', function (e) {
    e.preventDefault()
    $(this).find('video')[0].play();
  })*/
});
