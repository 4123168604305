function circleAnimation(item,tab){
  let current = $(item);
  let items = $('#circle .circle__type');
  let positions = $('#circle .circle__item');
  let nextTab = $(tab);
  
  $('#tabs-prodct .tab-pane').removeClass('show active');
  nextTab.addClass('show active');
  
  if(current.hasClass('is-top')){
    items.removeClass('is-active');
    let clone = positions.filter('.is-down').removeClass('is-down').addClass('is-top item-top').on('animationend',function(){
      $(this).removeClass('item-top');
    }).clone();
    clone.removeClass('is-top item-top').addClass('is-down')
      .addClass('item-down').appendTo('#circle');
    clone.on('animationend',function(){
      $(this).remove();
    });
    positions.filter('.is-center').removeClass('is-center').addClass('is-down');
    current.removeClass('is-top item-top').addClass('is-center')
      .find('.circle__type').addClass('is-active');
  }

  if(current.hasClass('is-down')){
    items.removeClass('is-active');
    let clone = positions.filter('.is-top').removeClass('is-top').addClass('is-down item-indown').on('animationend',function(){
      $(this).removeClass('item-indown');
    }).clone();
    clone.removeClass('is-down item-indown').addClass('is-top item-outtop')
      .prependTo('#circle');
    clone.on('animationend',function(){
      $(this).remove();
    });
    positions.filter('.is-center').removeClass('is-center').addClass('is-top');
    current.removeClass('is-down').addClass('is-center')
      .find('.circle__type').addClass('is-active');
  }
}
