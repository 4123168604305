if ($('#map-addresses').length > 0) {
  var form = $('#current_city');
  var city_coords = [$('#current_city_coords').val().split(' ')[1],$('#current_city_coords').val().split(' ')[0]];
  var objectsMap,clusterer;
  var searchTimer;
  var searchTimerDelay = 300;
  ymaps.ready(function () {
    $.ajax({
            url: '/brands.json',
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                // console.log( response );
                objectsMap = new ymaps.Map("map-addresses", {
                    center: city_coords,
                    zoom:10,
                    controls: []
                }, {
                    searchControlProvider: 'yandex#search',
                    suppressMapOpenBlock: true
                });

                version = ymaps.meta.version.replace(".", "-").replace(".", "-")
                var styleNode = document.createElement('style');
                styleNode.type = "text/css";
                css = '.ymaps-'+version+'-balloon {top:-109px!important;left:-148px!important;}';
                css += '.ymaps-'+version+'-balloon__layout {background: transparent!important; }';

                css += '.ymaps-'+version+'-balloon__close-button {'+
                'background: url(/images/icons/cards.svg) black no-repeat 50%!important;'+
                'height: 30px!important;padding: 5px;'+
                'border-radius: 50%;'+
                'opacity:1!important;'+
                'box-sizing: border-box!important;'+
                'width: 33px!important;'+
                'height: 33px!important; }'

                css += '.ymaps-'+version+'-default-cluster {'+
                'width: 48px!important;'+
                'height: 48px!important;'+
                'border-radius: 50%!important;'+
                'box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);'+
                'background-color: #ffffff!important;'+
                'border: solid 4px #ffffff!important;'+
                'box-sizing: border-box!important;'+
                'background: white!important;'+
                'line-height: 0!important;}'

                css += '.ymaps-'+version+'-default-cluster > ymaps {'+
                'border: solid 4px #0057a5!important;'+
                'width: 100%;'+
                'height: 100%;'+
                'line-height: 50px;'+
                'display: block;'+
                'border-radius: 50%;'+
                'box-sizing: border-box!important;}'

                css += '.ymaps-'+version+'-balloon__tail {'+
                'left: 50%!important;'+
                'top: -7px!important;'+
                'margin-left: 0px!important; }'

                css += '.last-apartments__card {'+
                'background: #fff; }'

                css += '.ymaps-'+version+'-balloon {'+
                'box-shadow: none!important; }'

                css += '.ymaps-'+version+'-balloon__close {'+
                'top: 18px!important;'+
                'left: -237px!important;}'

                css += '.ymaps-'+version+'-balloon__close+.ymaps-'+version+'-balloon__content {'+
                'margin-left: 0px!important;'+
                'margin-right: 0!important;'+
                'margin-top: 0px!important;'+
                'border: 0!important;'+
                'background: transparent!important;'+
                '}'

                if(!!(window.attachEvent && !window.opera)) {
                    styleNode.styleSheet.cssText = css;
                } else {
                    var styleText = document.createTextNode(css);
                    styleNode.appendChild(styleText);
                }
                document.getElementsByTagName('head')[0].appendChild(styleNode);


                var clusterIconContent = ymaps.templateLayoutFactory.createClass(
                    '<span class="cluster-count">$[properties.iconContent]</span>'
                );

                var objectIcon = ymaps.templateLayoutFactory.createClass(
                    '<div class="placemark-icon">' +
                        '<div class="icon__object-price">' +
                            '<div class="icon__rooms-quantity"><div class="icon__rooms-quantity-inner icon__rooms-quantity-inner$[properties.roomsQuantity]">$[properties.roomsQuantity]</div></div>' +
                            '<span class="price">$[properties.price]</span></div>' +
                        '</div>' +
                    '</div>'
                );

                ymaps.option.presetStorage.remove('default#image')
                ymaps.option.presetStorage.add('objects#group', {
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                    iconImageSize: [133, 23],
                    iconImageOffset: [-16, -58],

                    iconContentLayout: objectIcon,
                    hideIconOnBalloonOpen: true,
                    clusterIcons: [{
                        href: "images/cluster-icon.png",
                        size: [50, 50],
                        offset: [-20, -20],
                        shape: {
                            type: 'Circle',
                            coordinates: [0, 0],
                            radius: 20
                        }
                    }],
                    clusterIconContentLayout: clusterIconContent
                });

                clusterer = new ymaps.Clusterer({
                    preset: 'objects#group',
                    gridSize: 128,
                    hasBalloon: false,
                    hasHint: false,
                    groupByCoordinates: false,
                    clusterDisableClickZoom: false,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false,
                    maxZoom: 13
                });

                response.map(function(object) {
                    var lng = object.coord_lng;
                    //console.log(lng)
                    if (lng !== '' ) {
                        lng = lng.split(',');
                        placemark = new ymaps.Placemark(lng, {
                            balloonContent: '<div class="last-apartments__loading">' +
                                                '<div class="lds-rolling">' +
                                                    '<div></div>' +
                                                '</div>' +
                                            '</div>'
                        }, {
                            preset: 'objects#group'
                        });

                        object_price = object.regular_price
                        price = object_price!=null?(object_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + ' '):object_price;


                        placemark.icon = '';
                        placemark.properties.set('id', object.id);
                        placemark.properties.set('roomsQuantity', object.rooms_quantity==0?"с":object.rooms_quantity);
                        placemark.properties.set('price', price);

                        placemark.events.add('click', function (e) {
                            var thisPlacemark = e.get('target');
                            
                            
                            $.get(
                                "/get-object-by-id",
                                { objectId: thisPlacemark.properties.get('id') },
                                function (response) {
                                    $('.last-apartments__loading').css('display', 'none');
                                    thisPlacemark.properties.set('balloonContent', response);
                                    var ids = thisPlacemark.properties.get('id');
                                    var cors = thisPlacemark.geometry.getCoordinates();
                                    objectsMap.setCenter(cors, 18);
                                    
                                    $('.buy-beton-item-price').removeClass('active');
                                    $('.buy-beton-item'+ids).addClass('active');
                                    $('.buy-beton-item').hide();
                                    $('.buy-beton-item'+ids).show();
                                    $('.buy-beton-item'+ids).find('.buy-beton-item-price').addClass('active');
                                    
                                    
                                }
                            );

                        });
                        
                        
                        

                        clusterer.add(placemark);
                    }
                });

                objectsMap.geoObjects.add(clusterer);
                geoObjectsQuery = ymaps.geoQuery(clusterer.getGeoObjects());

                
            },
            error: function () {
                
            }
        });
  });

  //search in adressess
  var hiddenCount;
  $(document).on('keyup', '.search-address__input', function(event) {
    var self = this;
    var findText ='';
    var findCount = 0;
    clearTimeout(searchTimer);
    searchTimer = setTimeout(function() {
      $('.search_magazin_rezults > div').each(function(index, el) {
        findText = $(el).find('.address__title').text().toLowerCase();
        findText = findText + ' ' + $(el).find('.address__content').text().toLowerCase();
        if(findText.indexOf($(self).val().toLowerCase()) >= 0) {
          $(el).fadeIn();
          findCount++;
        } else {
          $(el).fadeOut(300, function() {
            $(el).attr("style", "display: none !important");
          });
        }
      });
      
      if(findCount == 0) {
        setTimeout(function() {
          $('.search_nofound').fadeIn();
        }, 300);
      } else {
        $('.search_nofound').hide();
      }
      if($(self).val().length > 0) {
        $('.search_loadmore').css('opacity', '0');
      } else {
        $('.search_magazin_rezults > div:gt(14)').attr('style', 'display:none!important');
        $('.search_loadmore').css('opacity', '1');
        hiddenCount = $('.search_magazin_rezults > div:hidden').length;
        if(hiddenCount < 15) {
          $('.search_loadmore span').text(hiddenCount + sklonenie(hiddenCount));
        }
        if(hiddenCount > 15) {
          $('.search_loadmore span').text(15 + sklonenie(15));
        }
      }
      
    }, searchTimerDelay);
    
  });
  $(document).on('submit', '.search-address', function(event) {
    event.preventDefault();
    searchTimerDelay = 0;
    $('.search-address__input').keyup();
    setTimeout(function() {searchTimerDelay = 300}, 400);
  });
  hiddenCount = $('.search_magazin_rezults > div:hidden').length;
  $(document).on('click', '.search_loadmore', function(event) {
    event.preventDefault();
    $('.search_magazin_rezults > div:hidden').slice(0,15).fadeIn().attr('style', 'display:flex!important');
    hiddenCount = $('.search_magazin_rezults > div:hidden').length;
    if(hiddenCount < 15) {
      $('.search_loadmore span').text(hiddenCount + sklonenie(hiddenCount));
    }
    if(hiddenCount > 15) {
      $('.search_loadmore span').text(15 + sklonenie(15));
    }
    if(hiddenCount <= 0) {
      $('.search_loadmore').css('opacity', '0');
    }
  });
  if(hiddenCount < 15) {
    $('.search_loadmore span').text($('.search_magazin_rezults > div:hidden').length + sklonenie($('.search_magazin_rezults > div:hidden').length));
  }

  function sklonenie(count){
    var phrase;
    switch (true) {
        case count == 0:
        case count > 4 && count < 20:
        case count > 110 && count < 115:
        case count % 10 > 4 && count % 10 < 10:
        case count % 10 == 0:
            phrase = "магазинов";
            break;
        case count == 1:
        case count % 10 == 1:
            phrase = "магазин";
            break;

        case count > 1 && count < 4:
        case count % 10 > 1 && count % 10 < 5:
            phrase = "магазина";
            break;

        default: phrase = "товаров";
    }
    return ' '+phrase;
  }

}