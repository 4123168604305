function initSlidersProduct(id){
  Array.from(document.querySelectorAll('#'+id)).forEach(function(el,i){
    let stype = 'gallery';
    let controlsId = document.getElementById(id).dataset.controls;
    let controlsContainer = controlsId + ' .slider-controls__btns';
    if(window.innerWidth<992){
      stype = 'carousel';
    }else{
      let item = el.querySelectorAll('.product__row');
      Array.from(item).forEach(function(el,i){
        let next = i+1;
        if (next > item.length - 1){
          next = 0;
        }
        $($(item[next]).find('.product__img:first-child')).clone().addClass('product__img--next').prependTo(el);
      });
    }
    let carousel = tns({
      container: el,
      mode: stype,
      items: 1,
      speed: 500,
      gutter: 0,
      controls: true,
      controlsContainer: controlsContainer,
      nav: true,
      navPosition: 'bottom',
      mouseDrag: true,
      preventScrollOnTouch: 'auto',
      swipeAngle: 25,
      touch: true,
      loop: false,
      rewind: true,
      animateIn: 'animation-in',
      animateOut: 'animation-out',
      responsive:{
        992:{
          mode: 'gallery',
          items: 1,
          gutter: 0,
          nav: false,
          mouseDrag: false,
          touch: false,
          speed: 800
        }
      },
      onInit: function(slider){
        if (slider.slideCount < 2){
          document.querySelector(controlsId).remove();
        }else{
          sliderCountChage(controlsId, 1, slider.slideCount);
          let active = document.querySelector('#'+ id +
            ' .tns-slide-active');
          document.querySelector('#product')
            .style.backgroundImage = "url(" +active.dataset.bg+")";
          setTimeout(function(){
            eventsCarousel();
          },200)
        }
        
      }
    });

    function eventsCarousel(){
      carousel.events.on('indexChanged',function(slider,eventName){

        let active = document.querySelector('#' + id +
          ' .tns-slide-active');
        sliderCountChage(controlsId, slider.displayIndex, slider.slideCount);
        document.querySelector('#product')
          .style.backgroundImage = "url(" +active.dataset.bg+")";
      });
      carousel.events.on('transitionStart',function(slider,eventName){
        if (slider.index < slider.indexCached){
          Array.from(slider.slideItems).forEach(function(el,i){
            el.classList.add('animation-back');
          });
        }else{
          Array.from(slider.slideItems).forEach(function(el,i){
            el.classList.remove('animation-back');
          });
        }
      });
      carousel.events.on('transitionEnd',function(slider,eventName){
        Array.from(slider.slideItems).forEach(function(el,i){
          if(el.classList.contains('tns-normal')){
            el.classList.remove('animation-back');
          }
        });
      });
    }
    $(document).on('click','.product__item .product__img--next a',function(e){
      if($(window).width()>991){
        e.preventDefault();
        carousel.goTo('next');
      }
    });
    function sliderCountChage(id,index,pages){
      document.querySelector(id + ' .slider-controls__count span')
        .textContent = index;
      document.querySelector(id + ' .slider-controls__count small')
        .textContent = '/' + pages;
    }
  });
}
document.addEventListener("DOMContentLoaded", function(){
  
  initSlidersProduct('product-slider');
  initSlidersProduct('product-slider-2');
  initSlidersProduct('product-slider-3');
  
});

if ( $('.js-product-page').length ) {

  function imageAnimationBefore() {
    var tl = gsap.timeline();
    tl.to(".product-card__center", {
      duration: 0.5,
      translateX: 50,
      opacity: 0,
    });
  }
  function bgAnimationBefore() {
    var tl = gsap.timeline();
    tl.to(".product-card__bg", {
      duration: 0.5,
      rotationY: 180,
      opacity: 0
    });
  }
  function headingAnimationBefore() {
    var tl = gsap.timeline();
    tl.to(".product-card__head h1", {
      duration: 0.5,
      opacity: 0
    });
  }
  function descrAnimationBefore() {
    var tl = gsap.timeline();
    tl.to(".product-card__descr", {
      duration: 0.5,
      translateY: 50,
      opacity: 0
    });
  }

  function imageAnimationAfter() {
    var tl = gsap.timeline();
    tl.from(".product-card__center", {
      duration: 0.5,
      translateX: -50,
      opacity: 0,
    });
  }
  function bgAnimationAfter() {
    var tl = gsap.timeline();
    tl.from(".product-card__bg", {
      duration: 0.5,
      rotationY: -180,
      opacity: 0
    });
  }
  function headingAnimationAfter() {
    var tl = gsap.timeline();
    tl.from(".product-card__head h1", {
      duration: 0.5,
      opacity: 0
    });
  }
  function descrAnimationAfter() {
    var tl = gsap.timeline();
    tl.from(".product-card__descr", {
      duration: 0.5,
      translateY: -50,
      opacity: 0
    });
  }

  function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
      setTimeout(() => {
        done();
      }, n);
    });
  }

  barba.init({
    sync: true,
    transitions: [{
      name: 'transition',
      async leave(data) {
        const done = this.async();
        imageAnimationBefore();
        bgAnimationBefore();
        headingAnimationBefore();
        descrAnimationBefore();
        await delay(300);
        done();
      },
      async enter(data) {
        imageAnimationAfter();
        bgAnimationAfter();
        headingAnimationAfter();
        descrAnimationAfter();
      }
    }],
    views: [
      {
        namespace: 'product5',
        afterEnter() {
          $('.product-card__link').on('click', function (e) {
            e.preventDefault()
            var target = $(this).attr('href')
            $('.product-card__link').removeClass('is-active')
            $(this).addClass('is-active')
            $('.product-card__imgs--slider img').removeClass('product-card__img--active')
            $(target).addClass('product-card__img--active')
          })
        }
      },
      {
        namespace: 'product',
        beforeEnter(){
          carouselLoad1 = false;
          carouselLoad2 = false;
        },
        afterEnter(){
          $('html, body').animate({scrollTop: 0}, 0);
          if(carouselLoad1 == false) {
            carouselLoad1 = true;
            Array.from(document.querySelectorAll('.js-slider-cards ')).forEach(function(el,i){
              let carousel = tns({
                container: el,
                items: 1,
                speed: 500,
                gutter: 10,
                controls: false,
                controlsText: arrowsSvg,
                controlsPosition: 'bottom',
                nav: true,
                navPosition: 'bottom',
                mouseDrag: true,
                preventScrollOnTouch: 'auto',
                swipeAngle: 25,
                touch: true,
                loop: true,
                responsive: {
                  576: {
                    items: 2,
                  },
                  992: {
                    nav: false,
                    controls: true,
                    gutter: 30,
                  }
                },
                onInit: function(slider){

                }
              });
            });
            
            
          }
          if(carouselLoad2 == false) {
            carouselLoad2 = true;
            if($('#recipe-slider').length > 0) {
              Array.from(document.querySelectorAll('#recipe-slider')).forEach(function(el,i){
              let carousel = tns({
                container: el,
                items: 1,
                speed: 500,
                gutter: 10,
                controls: true,
                controlsContainer: '#recipe-controls .slider-controls__btns',
                nav: true,
                navPosition: 'bottom',
                mouseDrag: true,
                preventScrollOnTouch: 'auto',
                swipeAngle: 25,
                touch: true,
                loop: false,
                rewind: true,
                fixedWidth: 240,
                responsive: {
                  576: {
                    items: 2,
                    fixedWidth: false
                  },
                  768: {
                    items: 3
                  },
                  992: {
                    nav: false,
                    slideBy: 'page'
                  },
                  1200:{
                    gutter: 30
                  }
                },
                onInit: function(slider){
                  if (slider.slideCount < 2){
                    document.querySelector('#recipe-controls').remove();
                  }else{
                    sliderCountChage('#recipe-controls', 1, slider.pages);
                  }
                  
                }
                
              }).events.on('indexChanged',function(slider,eventName){
                let indexPrev = slider.indexCached,
                    indexCurrent = slider.index;
                let page = Number(document.querySelector('#recipe-controls .slider-controls__count span')
                  .textContent);
                if (indexCurrent > indexPrev){
                  page++;
                }else{
                  page--;
                }
                if (indexCurrent === 0){
                  page = 1;
                }
                if (indexCurrent > slider.pages){
                  page = slider.pages;
                }
                sliderCountChage('#recipe-controls', page, slider.pages);
              });
              function sliderCountChage(id,index,pages){
                document.querySelector(id + ' .slider-controls__count span')
                  .textContent = index;
                document.querySelector(id + ' .slider-controls__count small')
                  .textContent = '/' + pages;
              }
            });
            }
          }
          
        }

      }
    ]
  })
  
}
