var fixedHeader = function () {
  if ($(this).scrollTop() > 1) {
    $('header').addClass('is-sticky')
  } else {
    $('header').removeClass('is-sticky')
  }
}
$(window).on('load scroll', function() {
  fixedHeader()
})
