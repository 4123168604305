document.addEventListener("DOMContentLoaded", function(){

  Array.from(document.querySelectorAll('#front-slider')).forEach(function(el,i){
    let carousel = tns({
      container: el,
      mode: 'gallery',
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      items: 1,
      speed: 500,
      gutter: 0,
      controls: true,
      controlsContainer: '#front-controls .slider-controls__btns',
      nav: false,
      mouseDrag: true,
      preventScrollOnTouch: 'auto',
      swipeAngle: 25,
      touch: true,
      loop: false,
      rewind: true,
      onInit: function(slider){
        if (slider.slideCount < 2){
          document.querySelector('#front-controls').remove();
        }else{
          sliderCountChage('#front-controls', 1, slider.slideCount);
        }
        
      }

    }).events.on('indexChanged',function(slider,eventName){
      let indexPrev = slider.indexCached,
        indexCurrent = slider.index;
      let page = Number(document.querySelector('#front-controls .slider-controls__count span')
        .textContent);
      if (indexCurrent > indexPrev){
        page++;
      }else{
        page--;
      }
      if (indexCurrent === 0){
        page = 1;
      }
      if (indexCurrent > slider.pages){
        page = slider.pages;
      }
      sliderCountChage('#front-controls', page, slider.slideCount);
    });
    function sliderCountChage(id,index,pages){
      document.querySelector(id + ' .slider-controls__count span')
        .textContent = index;
      document.querySelector(id + ' .slider-controls__count small')
        .textContent = '/' + pages;
    }
  });
  
});
