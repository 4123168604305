document.addEventListener("DOMContentLoaded", function(){

  Array.from(document.querySelectorAll('#js-way-slide')).forEach(function(el,i){
    let carousel = tns({
      container: el,
      items: 1,
      speed: 500,
      controls: true,
      controlsContainer: '#way-slider-controls .slider-controls__btns',
      nav: true,
      navPosition: 'bottom',
      mouseDrag: true,
      preventScrollOnTouch: 'auto',
      swipeAngle: 25,
      touch: true,
      loop: false,
      rewind: true,
      responsive: {
        992: {
          nav: false,
          mode: 'gallery'
        }
      },
      onInit: function(slider){
        if (slider.slideCount < 2){
          document.querySelector('#way-slider-controls').remove();
        }else{
          sliderCountChage('#way-slider-controls', 1, slider.slideCount);
        }
        
      }

    }).events.on('indexChanged',function(slider,eventName){
      let indexPrev = slider.indexCached,
        indexCurrent = slider.index;
      let page = Number(document.querySelector('#way-slider-controls .slider-controls__count span')
        .textContent);
      if (indexCurrent > indexPrev){
        page++;
      }else{
        page--;
      }
      if (indexCurrent === 0){
        page = 1;
      }
      if (indexCurrent > slider.pages){
        page = slider.pages;
      }
      sliderCountChage('#way-slider-controls', page, slider.slideCount);
    });
    function sliderCountChage(id,index,pages){
      document.querySelector(id + ' .slider-controls__count span')
        .textContent = index;
      document.querySelector(id + ' .slider-controls__count small')
        .textContent = '/' + pages;
    }
  });

});
