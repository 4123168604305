$(document).ready(function(){
  $("[data-src='#search']").fancybox({
    afterClose: function( instance, slide ) {
      let searchResult = $("#search-result");
      searchResult.slideUp();
    }
  });
})

function searchResultShow(val){
  let searchResult = $("#search-result");
    if (!val) {
        searchResult.slideUp();
        return false;
    }
    searchResult.slideDown();
}

