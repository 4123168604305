if ( $('#map-group').length > 0 ) {
    ymaps.ready(function () {
      var myMap = new ymaps.Map('map-group', {
          center: [59.937648, 30.286439],
          zoom: 14,
          controls: []
        }),
  
        
        myPlacemark = new ymaps.Placemark(myMap.getCenter(), null, {
          iconLayout: 'default#image',
          iconImageHref: 'img/marker.svg',
          iconImageSize: [53, 75],
          iconContentOffset: [33, 7],
          zIndex: 900
        });
        myMap.controls.remove('zoomControl');
        myMap.controls.remove('fullscreenControl');
        myMap.controls.remove('typeSelector');
        myMap.controls.remove('routeButtonControl');
        myMap.controls.remove('trafficControl');
        myMap.controls.remove('rulerControl');
      myMap.geoObjects.add(myPlacemark);
    });
  }
  