var carouselLoad1 = false;
var carouselLoad2 = false;
let arrowsSvg = ['<svg width="5" height="9" viewBox="0 0 5 9" fill="none"><path d="M0 4.5L4.5 0V9L0 4.5Z" fill="currentColor"/></svg>','<svg width="5" height="9" viewBox="0 0 5 9" fill="none" ><path d="M5 4.5L0.5 0V9L5 4.5Z" fill="currentColor"' +
  '</svg>\n'];
document.addEventListener("DOMContentLoaded", function(){
  
  if(carouselLoad1 == false) {
    carouselLoad1 = true;
    Array.from(document.querySelectorAll('.js-slider-cards ')).forEach(function(el,i){
      let carousel = tns({
        container: el,
        items: 1,
        speed: 500,
        gutter: 10,
        controls: false,
        controlsText: arrowsSvg,
        controlsPosition: 'bottom',
        nav: true,
        navPosition: 'bottom',
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        swipeAngle: 25,
        touch: true,
        loop: true,
        responsive: {
          576: {
            items: 2,
          },
          992: {
            nav: false,
            controls: true,
            gutter: 30,
          }
        },
        onInit: function(slider){

        }
      });
    });
  }
  
  
});