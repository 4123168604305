$(document).ready(function() {

  let perfectScrollbarOptions = {
    wheelSpeed: 0.5,
    wheelPropagation: false
  }
  
  let select2Default = $('.js-select2-default');
  select2Default.each(function(i,el){
    let parentDrop = $(el).parents('.popup-form');
    if (parentDrop.length<1){
      parentDrop = $('body');
    }
    $(el).select2({
      minimumResultsForSearch: -1,
      width: '100%',
      dropdownParent: parentDrop
    });
    $(el).on('select2:open', function () {
      setTimeout(() => {
        let ps = new PerfectScrollbar('.select2-results__options', perfectScrollbarOptions)
      }, 0)
    })
  });
  
  let select2Multiple = $('.js-select2-multiple');
  select2Multiple.each(function(i,el){
    let parentDrop = $(el).parents('.popup-form');
    if (parentDrop.length<1){
      parentDrop = $('body');
    }
    $(el).select2({
      minimumResultsForSearch: -1,
      width: '100%',
      closeOnSelect: false,
      dropdownCssClass: 'select2-dropdown--multiple',
      dropdownParent: parentDrop
    })
    $(el).on('select2:open', function () {
      setTimeout(() => {
        let ps = new PerfectScrollbar('.select2-results__options', perfectScrollbarOptions)
      }, 0)
    });
  });
  
});
